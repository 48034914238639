<template>
  <div>
    <product></product>
  </div>
</template>
<style>
  .agile {
    width: 100%;
  }
  .agile__actions {
    margin-top: 0px;
  }
  .agile__nav-button {
    background: transparent;
    border: none;
    color: #ccc;
    cursor: pointer;
    font-size: 24px;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
  }
  .agile__nav-button:hover {
    color: #888;
  }
  .agile__dot {
    margin: 0 10px;
  }
  .agile__dot button {
    background-color: #eee;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 10px;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    width: 10px;
  }
  .agile__dot--current button, .agile__dot:hover button {
    background-color: #888;
  }
</style>
<script>
import Slider from '../home/Slider'
import Product from './Product'
export default {
  name: 'Sanpham',
  components: {Slider,Product}
}
</script>

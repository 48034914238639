<template>
  <div>
    <section class="info-section py-0 mt-5">
        <div class="container">
          <div class="row">
            <div class="col content-half">
              <div class="head-box text-center col-md-12">
                <h3 class="text-success">DANH SÁCH SẢN PHẨM</h3>
                <h6 class="text-dark text-underline-primary mb-2">Sẵn sàng triển khai & cho dùng thử 100 ngày miễn phí</h6>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/cafe"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/cafe-icon.png?alt=media&token=b2620335-c0d4-4f48-9b58-2ee9bae384a0"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/cafe" class="text-dark">QUẢN LÝ CHUỖI QUÁN CAFE QUẢN LÝ CHUỖI QUÁN CAFE</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/vlxd"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/vlxd-new.png?alt=media&token=204386f4-d4b8-4568-85ea-8ec3bfd06d76"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/vlxd" class="text-dark">QUẢN LÝ CHUỖI CỬA HÀNG VẬT LIỆU XÂY DỰNG</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                    <a href="/product/dentist"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/dentist-new.png?alt=media&token=b0bab8d8-ec52-4d9b-9ceb-8dbea01b1884"
                    alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/dentist" class="text-dark">QUẢN LÝ CHUỖI PHÒNG KHÁM NHA KHOA</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/pharmacy"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/thuoc-tay-new.png?alt=media&token=73786489-0982-428d-b384-bfebb4b3e53a"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/pharmacy" class="text-dark">QUẢN LÝ CHUỖI QUẦY BÁN THUỐC TÂY</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/karaoke"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/karaoke-new.png?alt=media&token=bdb35737-d51a-4081-b5a1-3cebf5a6257f"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/karaoke" class="text-dark">QUẢN LÝ CHUỖI PHÒNG KARAOKE</a>
                    </div>
                  </div>
                </div>
                
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/food"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/food-new.png?alt=media&token=61f07499-9bac-453e-b1c8-a8cc3b38ce67"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/food" class="text-dark">QUẢN LÝ CHUỖI NHÀ HÀNG & QUÁN ĂN</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/pawn"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/camdo-new.png?alt=media&token=a19d3bfa-a377-4586-8df7-c0b304e4566b"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/pawn" class="text-dark">QUẢN LÝ CHUỖI CỦA HÀNG CẦM ĐỒ</a>
                    </div>
                  </div>
                </div>
                
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/football"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/bongda-new.png?alt=media&token=7f1812eb-adec-4669-a313-de9b089fc971"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/football" class="text-dark">QUẢN LÝ CHUỖI SÂN BÓNG ĐÁ</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/tole"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/tole-sat-thep-new.png?alt=media&token=e454c777-3c31-4fb1-b86e-ccebbef8d2b9"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/tole" class="text-dark">QUẢN LÝ CHUỖI CƠ SỞ KINH DOANH TOLE-SẮT-THÉP</a>
                    </div>
                  </div>
                </div>
                
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/hotel"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/hotel-new.png?alt=media&token=1e440e79-63ad-4b8a-9717-7727605a0690"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/hotel" class="text-dark">QUẢN LÝ CHUỖI NHÀ NGHỈ & KHÁCH SẠN</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/hostel"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/hotel-new.png?alt=media&token=1e440e79-63ad-4b8a-9717-7727605a0690"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/hostel" class="text-dark">QUẢN LÝ CHUỖI CHO THUÊ NHÀ TRỌ</a>
                    </div>
                  </div>
                </div>
                
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                      <a href="/product/water"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/vlxd-new.png?alt=media&token=204386f4-d4b8-4568-85ea-8ec3bfd06d76"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/water" class="text-dark">QUẢN LÝ CHUỖI KINH DOANH BIA & NƯỚC GIẢI KHÁT</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                    <a href="/product/bida"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/bida-new.png?alt=media&token=e8cd6efd-106e-4f85-a1ea-9b60f2e5f1a4"
                      alt="avatar" width="160" height="80" class="border"></a>
                    </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                      <a href="/product/bida" class="text-dark">QUẢN LÝ CHUỖI BÀN BIDA</a>
                    </div>
                  </div>
                </div>
                
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                  <div class="row">
                    <div class="col">
                    <a href="/product/spa"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/spa-icon.png?alt=media&token=66738bcd-e3f0-4816-9575-1aef5c060da1"
                    alt="avatar" width="160" height="80" class="border"></a>
                  </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                    <a href="/product/spa" class="text-dark">QUẢN LÝ CHUỖI CƠ SỞ SPA</a>
                  </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4 mb-2">
                  <div class="row">
                    <div class="col">
                    <a href="/product/oto"><img src="https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/oto-icon.png?alt=media&token=ba09d9c9-02c0-4856-808b-b8c838197fc7"
                    alt="avatar" width="160" height="80" class="border"></a>
                  </div>
                    <div class="col mt-2 text-dark nopadding div-text">
                    <a href="/product/oto" class="text-dark">QUẢN LÝ CHUỖI CƠ SỞ SỬA CHỮA OTO & XE MÁY</a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>
</template>
<style scoped>
  .div-text{
    margin-left: -45px !important;
  }
</style>
<script>
import Slider from '../home/Slider'
export default {
  name: 'Product',
  components: {Slider}
}
</script>

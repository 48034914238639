<template>
  <div class="widget widget--carousel">
    <div class="container">
      <div id="app">
        <agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
          <div class="slide" v-for="(slide, index) in slides" :key="index"
          :class="`slide--${index}`"><img :src="slide"/></div>
        </agile>
        <agile class="thumbnails" ref="thumbnails" :options="options2" :as-nav-for="asNavFor2">
          <div class="slide slide--thumbniail" v-for="(slide, index) in slides"
          :key="index" :class="`slide--${index}`" @click="$refs.thumbnails.goTo(index)"><img :src="slide"/></div>
          <template slot="prevButton"><i class="fa fa-chevron-left"></i></template>
          <template slot="nextButton"><i class="fa fa-chevron-right"></i></template>
        </agile>
      </div>
    </div>
  </div>
</template>
<style>
  #app {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    margin: 0 auto;
  }

  .main {
    margin-bottom: 20px;
    margin-left: 0px !important;
  }

  .thumbnails {
    margin: 0 -5px;
    width: calc(100% + 10px);
  }

  .agile__nav-button {
    background: transparent;
    border: none;
    color: #ccc;
    cursor: pointer;
    font-size: 24px;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
  }
  .thumbnails .agile__nav-button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .thumbnails .agile__nav-button--prev {
    left: -45px;
  }
  .thumbnails .agile__nav-button--next {
    right: -45px;
  }
  .agile__nav-button:hover {
    color: #888;
  }
  .agile__dot {
    margin: 0 10px;
  }
  .agile__dot button {
    background-color: #eee;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 10px;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    width: 10px;
  }
  .agile__dot--current button, .agile__dot:hover button {
    background-color: #888;
  }

  .slide {
    -webkit-box-align: center;
            align-items: center;
    box-sizing: border-box;
    color: #fff;
    display: -webkit-box;
    display: flex;
    /* height: 500px; */
    -webkit-box-pack: center;
            justify-content: center;
  }
  .slide--thumbniail {
    cursor: pointer;
    height: 100px;
    padding: 0 5px;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .slide--thumbniail:hover {
    opacity: 0.75;
  }
  .slide img {
    height: 100%;
    -o-object-fit: cover;
      object-fit: cover;
    -o-object-position: center;
      object-position: center;
    width: 100%;
  }
</style>
<script>
import { VueAgile } from 'vue-agile'
export default {
  name: 'Slider',
  components: {
    agile: VueAgile
  },
  data () {
		return {
			asNavFor1: [],
			asNavFor2: [],
			options1: {
				dots: false,
				fade: true,
				navButtons: false
			},

			options2: {
				autoplay: true,
				centerMode: true,
				dots: false,
				navButtons: false,
				slidesToShow: 3,
				responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 5
                }
            },

            {
                breakpoint: 1000,
                settings: {
                    navButtons: true
                }
            }
        ]

			},

			slides: [
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/cafe-icon.png?alt=media&token=b2620335-c0d4-4f48-9b58-2ee9bae384a0',//cafe
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/gym-icon.png?alt=media&token=a2cd3a30-7891-412b-bd3e-bcaddccb8fc0',//gym
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/spa-icon.png?alt=media&token=66738bcd-e3f0-4816-9575-1aef5c060da1',//spa
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/oto-icon.png?alt=media&token=ba09d9c9-02c0-4856-808b-b8c838197fc7',//oto
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/bida-new.png?alt=media&token=e8cd6efd-106e-4f85-a1ea-9b60f2e5f1a4',//bida
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/dentist-new.png?alt=media&token=b0bab8d8-ec52-4d9b-9ceb-8dbea01b1884',//dentist
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/food-new.png?alt=media&token=61f07499-9bac-453e-b1c8-a8cc3b38ce67',//food
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/bongda-new.png?alt=media&token=7f1812eb-adec-4669-a313-de9b089fc971',//football
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/hotel-new.png?alt=media&token=1e440e79-63ad-4b8a-9717-7727605a0690',//hotel
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/karaoke-new.png?alt=media&token=bdb35737-d51a-4081-b5a1-3cebf5a6257f',//karaoke
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/camdo-new.png?alt=media&token=a19d3bfa-a377-4586-8df7-c0b304e4566b',//pawn
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/thuoc-tay-new.png?alt=media&token=73786489-0982-428d-b384-bfebb4b3e53a',//pharmacy
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/tole-sat-thep-new.png?alt=media&token=e454c777-3c31-4fb1-b86e-ccebbef8d2b9',//tole
        'https://firebasestorage.googleapis.com/v0/b/sangcao-1264.appspot.com/o/vlxd-new.png?alt=media&token=204386f4-d4b8-4568-85ea-8ec3bfd06d76'//vlxd
      ]
		}
	},
	mounted () {
		this.asNavFor1.push(this.$refs.thumbnails)
		this.asNavFor2.push(this.$refs.main)
	}
}
</script>
